import { ReactNode } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import HeadingView from '../typography/heading';
import { Home } from '@mui/icons-material';

const MyServicesView = () => {
	return (
		<Box sx={{ backgroundColor: '#F8F9FA', padding: { xs: '60px 20px', md: '80px 100px' }, }}>
			<HeadingView backgroundTextColor={'#EDF0F1'} backgroundText={"Services"} foregroundText={"What I Do?"} />
			<Grid container spacing={4}>
				<ServiceItemView
					Icon={<Home sx={{ color: '#20C997', fontSize: '28px', }} />}
					title={"title"}
					description={"Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure."}
				/>
				<ServiceItemView
					Icon={<Home sx={{ color: '#20C997', fontSize: '28px', }} />}
					title={"title"}
					description={"Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure."}
				/>
				<ServiceItemView
					Icon={<Home sx={{ color: '#20C997', fontSize: '28px', }} />}
					title={"title"}
					description={"Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure."}
				/>
				<ServiceItemView
					Icon={<Home sx={{ color: '#20C997', fontSize: '28px', }} />}
					title={"title"}
					description={"Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure."}
				/>
				<ServiceItemView
					Icon={<Home sx={{ color: '#20C997', fontSize: '28px', }} />}
					title={"title"}
					description={"Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure."}
				/>
			</Grid>
		</Box>
	);
};

type ServiceItemViewProps = {
	Icon: ReactNode;
	title: string;
	description: string;
};

const ServiceItemView = (props: ServiceItemViewProps) => {
	return (
		<Grid item xs={12} md={6}>
			<Box sx={{ display: 'flex', alignItems: 'flex-start', padding: '20px' }}>
				<Paper
					sx={{
						width: '30px',
						height: '30px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginRight: '20px',
						padding: '20px',
						borderRadius: '6px',
						boxShadow: 3,
						backgroundColor: '#FFFFFF',
					}}
				>
					{props.Icon}
				</Paper>
				<Box>
					<Typography variant={'h6'} sx={{ color: '#000000', fontWeight: '500', }}>
						{props.title}
					</Typography>
					<Typography sx={{ color: '#000000', }}>
						{props.description}
					</Typography>
				</Box>
			</Box>
		</Grid>
	);
};

export default MyServicesView;
