import { Box, Typography, Divider } from '@mui/material';
import HeadingView from '../typography/heading';

const AboutMeView = () => {
	return (
		<Box sx={{ backgroundColor: '#FFFFFF', padding: { xs: '60px 20px', md: '80px 100px' }, }}>
			<HeadingView backgroundTextColor={'#F1F3F4'} backgroundText={"About Me"} foregroundText={"Know Me More"} />
			<Typography sx={{ fontSize: '2rem', fontWeight: 'bold', color: '#000000' }}>
				{"I'm "}<span style={{ color: '#20C997' }}>{"Sonia Jindal"}</span>{", a Corporate Coach"}
			</Typography>
			<Typography sx={{ fontSize: '1rem', lineHeight: 1.6, color: '#000000', marginTop: 2, }}>
				{"With over 20 years of proven experience, I specialize in leading and building high-availability, high-volume, and highly-scalable distributed systems and web services, with a strong focus on AIML-driven solutions."}
			</Typography>
			<Typography sx={{ fontSize: '1rem', lineHeight: 1.6, color: '#000000', marginTop: 2, }}>
				{"My expertise spans Java-based object-oriented design, design patterns, and relational databases, alongside extensive experience in Big Data (Hadoop, Spark, HDFS, Hive), distributed computing, and REST/SOAP services."}
			</Typography>

			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginX: 6, marginY: 6, }}>
				<StatView topText={"20+"} bottomText={"Years Experience"} divider={true} />
				<StatView topText={"25+"} bottomText={"Happy Clients"} divider={true} />
				<StatView topText={"65+"} bottomText={"Projects Done"} divider={true} />
				<StatView topText={"7"} bottomText={"Awards"} divider={false} />
			</Box>
		</Box>
	);
};

type StatViewProps = {
	topText: string;
	bottomText: string;
	divider?: boolean;
};

const StatView = (props: StatViewProps) => {
	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingY: 1, }}>
				<Typography sx={{ color: '#6C757D', fontSize: '2.5rem', fontWeight: 'bold', }}>{props.topText}</Typography>
				<Typography sx={{ color: '#8D8E8E', fontSize: '1rem', fontWeight: '500', }}>{props.bottomText}</Typography>
			</Box>
			{
				props?.divider
				&&
				<Divider orientation={'vertical'} flexItem sx={{ opacity: 0.5, }} />
			}
		</>
	);
};

export default AboutMeView;
