import { ReactNode, useState } from 'react';
import { Box, Avatar, List, ListItem, ListItemText, Typography, IconButton, Stack } from '@mui/material';
import { Facebook, Twitter, LinkedIn, Instagram } from '@mui/icons-material';
import { RESOURCES } from '../../utils';

type PanelViewProps = {
	name: string;
};

const PanelView = (props: PanelViewProps) => {
	const HREFS = [
		{ href: '#welcome', text: "Home" },
		{ href: '#about', text: "About Me" },
		{ href: '#services', text: "What I Do" },
		{ href: '#testimonials', text: "Testimonials" },
		{ href: '#portfolio', text: "Portfolio" },
		{ href: '#contact', text: "Contact" },
	];
	const [selectedHref, setSelectedHref] = useState('#welcome');
	function handleHrefClick(href: string) {
		const targetElement = document.querySelector(href);
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth' });
			setSelectedHref(href);
		}
	};
	return (
		<Box
			sx={{
				width: '250px',
				height: '100vh',
				bgcolor: '#111418',
				color: '#FFFFFF',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				alignItems: 'center',
				position: 'fixed',
				left: 0,
				top: 0,
				paddingX: 2,
			}}
		>
			<Box sx={{ flex: 2.5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: 2, }}>
				<Avatar
					sx={{ width: 150, height: 150, border: '8px solid #343A40' }}
					src={RESOURCES.IMAGES.PROFILE}
					alt={'Coach Profile'}
				/>
				<Typography
					sx={{ color: '#FFFFFF', fontWeight: 'bold', marginTop: 2, }}
					variant={'h5'}
				>
					{props.name}
				</Typography>
			</Box>

			<Box sx={{ flex: 4, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
				<List sx={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
					{
						HREFS.map((item, _) => (
							<LinkButtonView
								key={item.href}
								selectedHref={selectedHref}
								href={item.href}
								text={item.text}
								onClick={() => handleHrefClick(item.href)}
							/>
						))
					}
				</List>
			</Box>

			<Box sx={{ flex: 1, display: 'flex', alignItems: 'flex-end', width: '100%', marginBottom: 2, }}>
				<Stack sx={{ width: '100%', justifyContent: 'center', }} direction={'row'} spacing={2}>
					<SocialIconView link={'https://github.com/'} Icon={<Facebook />} />
					<SocialIconView link={'https://github.com/'} Icon={<Twitter />} />
					<SocialIconView link={'https://github.com/'} Icon={<Instagram />} />
					<SocialIconView link={'https://www.linkedin.com/in/sonia-j-9952b17/'} Icon={<LinkedIn />} />
				</Stack>
			</Box>
		</Box>
	);
};

type LinkButtonViewProps = {
	selectedHref: string;
	href: string;
	text: string;
	onClick: () => void;
};

const LinkButtonView = (props: LinkButtonViewProps) => {
	return (
		<ListItem
			sx={{ cursor: 'pointer', color: props.selectedHref == props.href ? '#20C997' : '#FFFFFF', '&:hover': { color: '#20C997' }, '&:visited': { color: '#FFFFFF' } }}
		// component={'a'} 
		// href={props.href}
		>
			<ListItemText
				sx={{ textAlign: 'center', }} primary={props.text}
				onClick={() => props.onClick()}
			/>
		</ListItem>
	);
};

type SocialIconViewProps = {
	link: string;
	Icon: ReactNode;
};

const SocialIconView = (props: SocialIconViewProps) => {
	return (
		<IconButton
			sx={{ color: '#FFFFFF', '&:hover': { color: '#0A66C2' }, }}
			href={props.link}
			target={'_blank'}
			size={'small'}
		>
			{props.Icon}
		</IconButton>
	);
};

export default PanelView;
