import { DateLog } from "./format.method";

type NetworkLog = {
	type: 'Request' | 'Response' | 'Error' | 'Exception';
	url: string;
	method: string;
	payload?: any;
};

export function NetworkLog({ type, url, method, payload = null }: NetworkLog) {
	console.log(`API ${method} ${type} - (${url}) - [${DateLog()}]\n payload = {\n${JSON.stringify(payload)}\n}`);
};
