import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { STRING } from '../utils';
import { HomePage } from '../pages';

const RootRouter = () => {
	return (
		createBrowserRouter(
			createRoutesFromElements(
				<>
					<Route path={STRING.ROUTES.NO_URL} element={<HomePage/>} />
				</>
			)
		)
	);
};

export default RootRouter;
