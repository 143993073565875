import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import HeadingView from '../typography/heading';

const ContactView = () => {
	const [formData, setFormData] = useState({ name: '', email: '', message: '' });
	const [errors, setErrors] = useState({ name: false, email: false, message: false });

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
		setErrors({ ...errors, [event.target.name]: false });
	};

	const validateEmail = (email: string) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handleSendEmail = () => {
		const { name, email, message } = formData;

		const newErrors = {
			name: name.trim() === '',
			email: !validateEmail(email),
			message: message.trim() === '',
		};
		setErrors(newErrors);

		if (Object.values(newErrors).some((error) => error)) {
			return
		};

		const subject = encodeURIComponent(`Message from ${name}`);
		const body = encodeURIComponent(`Name: ${name}\nEmail: ${email}\n\nMessage:\n${message}`);
		window.open(`mailto:soniajindal@example.com?subject=${subject}&body=${body}`, '_blank');
	};

	return (
		<Box sx={{ backgroundColor: '#FFFFFF', padding: { xs: '60px 20px', md: '80px 100px' } }}>
			<HeadingView backgroundTextColor={'#F1F3F4'} backgroundText={"Contact"} foregroundText={"Get in Touch"} />
			{
				// TODO: add address here: Sunnyvale, California, United States
			}
			<Typography
				variant={'h5'}
				sx={{
					textAlign: 'left',
					marginBottom: 4,
					fontWeight: '500',
					color: '#333333',
					maxWidth: '65%',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			>
				{"Send Us an Email"}
			</Typography>

			<Box component="form" sx={{ maxWidth: '800px', margin: '0 auto' }} onSubmit={(event) => event.preventDefault()}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<TextField
							label={"Name"}
							fullWidth
							required
							name="name"
							value={formData.name}
							onChange={handleChange}
							error={errors.name}
							helperText={errors.name ? "Name is required" : ""}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label={"Email"}
							type={"email"}
							fullWidth
							required
							name="email"
							value={formData.email}
							onChange={handleChange}
							error={errors.email}
							helperText={errors.email ? "Enter a valid email address" : ""}
						/>
					</Grid>
				</Grid>

				<Box sx={{ marginTop: 3 }}>
					<TextField
						label={"Tell me about your needs..."}
						multiline
						rows={8}
						fullWidth
						required
						name="message"
						value={formData.message}
						onChange={handleChange}
						error={errors.message}
						helperText={errors.message ? "Message is required" : ""}
					/>
				</Box>

				<Box sx={{ textAlign: 'center', marginTop: 4 }}>
					<Button
						variant="contained"
						onClick={handleSendEmail}
						sx={{
							backgroundColor: '#20C997',
							borderRadius: '50px',
							padding: '10px 30px',
							fontSize: '16px',
							color: '#FFF',
							'&:hover': { backgroundColor: '#1BAF84' },
						}}
					>
						{"Send Message"}
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default ContactView;
