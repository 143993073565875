import React from 'react';
import { Box, Typography } from '@mui/material';

type HeadingViewProps = {
	backgroundTextColor: string;
	backgroundText: string;
	foregroundText: string;
};

const HeadingView = (props: HeadingViewProps) => {
	return (
		<Box
			sx={{
				position: 'relative',
				width: '100%',
				paddingY: 4,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: 2,
				marginBottom: 8,
			}}
		>
			<Typography
				sx={{
					position: 'absolute',
					fontSize: '8rem',
					fontWeight: '900',
					color: props.backgroundTextColor,
					zIndex: 1,
					opacity: 0.7,
					textTransform: 'uppercase',
				}}
			>
				{props.backgroundText}
			</Typography>

			<Typography
				sx={{
					position: 'relative',
					fontSize: '2.5rem',
					color: '#000000',
					zIndex: 2,
					fontWeight: 'bold',
					'&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: '50%',
            width: '80px',
            height: '3px',
            backgroundColor: '#20C997',
            transform: 'translateX(-50%)',
          },
				}}
			>
				{props.foregroundText}
			</Typography>
		</Box>
	);
};

export default HeadingView;
