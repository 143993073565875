import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './utils/network/API.util';

import { RouterProvider } from 'react-router-dom';
import { RootRouter } from './routes';
import { SnackbarView, WaitView } from './components';

const App = () => {

  return (
    <>
      <SnackbarView />
      <WaitView />
      <RouterProvider router={RootRouter()} />
    </>
  );
};

export default App;
