import { Box, Typography, Grid, Chip } from '@mui/material';
import HeadingView from '../typography/heading';

const ResumeView = () => {
	return (
		<Box sx={{ backgroundColor: '#FFFFFF', padding: { xs: '60px 20px', md: '80px 100px' }, }}>
			<HeadingView backgroundTextColor={'#EDF0F1'} backgroundText={"Summary"} foregroundText={"Resume"} />
			<Grid container spacing={4}>
				<Grid item xs={12} md={6}>
					<Typography variant={'h5'} sx={{ fontWeight: 'bold', marginBottom: '20px' }}>{"My Education"}</Typography>
					<ResumeItemView
						chipText={"2015 - 2019"}
						title={"B.Sc in Computer Science"}
						subtitle={"University of XYZ"}
						description={"Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure."}
					/>
					<ResumeItemView
						chipText={"2015 - 2019"}
						title={"B.Sc in Computer Science"}
						subtitle={"University of XYZ"}
						description={"Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure."}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Typography variant={'h5'} sx={{ fontWeight: 'bold', marginBottom: '20px' }}>{"My Experience"}</Typography>
					<ResumeItemView
						chipText={"2015 - 2019"}
						title={"B.Sc in Computer Science"}
						subtitle={"University of XYZ"}
						description={"Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure."}
					/>
					<ResumeItemView
						chipText={"2015 - 2019"}
						title={"B.Sc in Computer Science"}
						subtitle={"University of XYZ"}
						description={"Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure."}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

type ResumeItemViewProps = {
	chipText: string;
	title: string;
	subtitle: string;
	description: string;
};

const ResumeItemView = ({ chipText, title, subtitle, description }: ResumeItemViewProps) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				padding: '20px',
				borderRadius: '8px',
				border: '1px solid #DEE2E6',
				marginBottom: '20px',
			}}
		>
			<Chip
				sx={{ backgroundColor: '#20C997', color: '#FFFFFF', marginBottom: '10px', width: 'fit-content', borderRadius: '8px', fontWeight: '600', fontSize: '14px', }}
				label={chipText}
			/>
			<Typography variant={'h6'} sx={{ fontWeight: '600', color: '#000000', marginTop: 0.5, }}>{title}</Typography>
			<Typography sx={{ color: '#DC3545', fontSize: '18px', marginTop: 1, marginBottom: 2, }}>{subtitle}</Typography>
			<Typography sx={{ color: '#000000', fontSize: '16px', marginY: 1, }}>{description}</Typography>
		</Box>
	);
};

export default ResumeView;
