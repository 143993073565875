const USER_ROLES: Record<string, string> = {
	ADMIN: 'admin',
	STORE_OWNER: 'storeOwner',
	STAFF_MEMBER: 'staffMember',
};

type RolesAccess = {
	[key: string]: {
		view: boolean;
	} | undefined;
};

const ROLES_ACCESS: Record<keyof typeof USER_ROLES, RolesAccess> = {
	ADMIN: {
		OverviewPage: {
			view: true,
		},
		ProfilePage: {
			view: true,
		},
		SettingsPage: {
			view: true,
		},
	},
	STORE_OWNER: {
		OverviewPage: {
			view: true,
		},
	},
	STAFF_MEMBER: {
	},
};

export default {
	USER_ROLES,
	ROLES_ACCESS,
};
