import { AlertColor } from '@mui/material';
import { create } from 'zustand';

interface SnackbarState {
	snackbar: {
		anchorOrigin: { vertical: 'top' | 'bottom'; horizontal: 'center' | 'right' | 'left'; };
		isVisible: boolean;
		variant: AlertColor;
		OnClose: () => void;
		message: string;
		key: string;
	},
	ShowSnackbar: (message: string, variant?: AlertColor) => void;
	HideSnackbar: () => void;
};

export const useSnackbar = create<SnackbarState>()((set) => ({
	snackbar: {
		anchorOrigin: { vertical: 'top', horizontal: 'right' },
		isVisible: false,
		variant: 'info',
		OnClose: () => { },
		message: '',
		key: Date.now().toString(),
	},
	ShowSnackbar: (message: string, variant: AlertColor = 'error') => set((state: SnackbarState) => {
		return { snackbar: { ...state.snackbar, isVisible: true, message, variant: variant } };
	}),
	HideSnackbar: () => set((state: SnackbarState) => ({ snackbar: { ...state.snackbar, isVisible: false, message: '' } })),
}));
