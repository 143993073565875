import { Backdrop, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { useWait } from '../../stateManagement';

const WaitView = () => {

	const { shouldWait } = useWait((state) => (state));

	// TODO: transition
	// TODO: styling
	// TODO: text UI

	return (
		<Backdrop
			sx={{ zIndex: 999 }}
			open={shouldWait}
			onClick={() => { }}
		>
			<Card sx={{ minWidth: 275 }}>
				<CardContent style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
					<CircularProgress size={30} hidden={shouldWait} />
					<Typography variant={'body1'}>
						{"Processing ..."}
					</Typography>
				</CardContent>
			</Card>
		</Backdrop>
	);
};

export default WaitView;
