import { create } from 'zustand';
import { STRING, TYPE } from '../utils';

interface UserState {
	user: TYPE.UserStore,
	SetUser: (user: TYPE.UserStore) => void;
};

export const useUserStore = create<UserState>()((set) => ({
	user: {
		userId: '',
		businessId: '',
		userRole: Object.keys(STRING.LISTS.USER_ROLES)[0],
		accessToken: '',
		refreshToken: '',
		expires: null,
		userName: 'Some User',
		profilePic: 'https://static.vecteezy.com/system/resources/thumbnails/036/594/092/small/man-empty-avatar-photo-placeholder-for-social-networks-resumes-forums-and-dating-sites-male-and-female-no-photo-images-for-unfilled-user-profile-free-vector.jpg', // TODO: fix this later
	},
	SetUser: (user: TYPE.UserStore) => set((state: UserState) => ({ user: { ...state.user, ...user } })),
}));
