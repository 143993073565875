export default {
	NOT_FOUND: '*',
	NO_URL: '/',

	LOGOUT: '/login',
	LOGIN: '/login',
	REGISTER: '/register',
	FORGOT_PASSWORD: '/forgotPassword',

	DASHBOARD: '/dashboard',
	OVERVIEW: '/dashboard/overview',
	PROFILE: '/dashboard/profile',
	SETTINGS: '/dashboard/settings',
};
