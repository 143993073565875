import { Alert, Snackbar } from '@mui/material';
import { useSnackbar } from '../../stateManagement';
import { STRING } from '../../utils';

const SnackbarView = () => {

	const { key, anchorOrigin, isVisible, variant, message, OnClose, } = useSnackbar((state) => (state.snackbar));
	const HideSnackbar = useSnackbar((state) => (state.HideSnackbar));

	// TODO: transition
	// TODO: styling

	function HandleClose() {
		HideSnackbar();
		OnClose();
	};

	return (
		<>
			<Snackbar
				key={key}
				anchorOrigin={anchorOrigin}
				autoHideDuration={STRING.TIME.LONG * 2}
				open={isVisible}
				message={message}
				onClose={() => HandleClose()}
			>
				<Alert
					severity={variant}
					variant={'filled'}
					sx={{ width: '100%' }}
					onClose={() => HandleClose()}
				>
					{message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default SnackbarView;
