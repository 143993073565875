import { create } from 'zustand';

interface WaitState {
	shouldWait: boolean;
	ShowWait: () => void;
	HideWait: () => void;
};

export const useWait = create<WaitState>()((set) => ({
	shouldWait: false,
	ShowWait: () => set((state: WaitState) => ({ shouldWait: true })),
	HideWait: () => set((state: WaitState) => ({ shouldWait: false })),
}));
