import { useState } from 'react';
import { Box, Typography, Grid, Paper, Avatar, Rating } from '@mui/material';
import HeadingView from '../typography/heading';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

type TestimonialsViewProps = {
	testimonials: any[];
};

const TestimonialsView = (props: TestimonialsViewProps) => {
	return (
		<Box sx={{ backgroundColor: '#F8F9FA', padding: { xs: '60px 20px', md: '80px 100px' } }}>
			<HeadingView backgroundTextColor={'#EDF0F1'} backgroundText={"Testimonial"} foregroundText={"Clients Say"} />
			<TestimonialSliderView testimonials={props.testimonials} />
		</Box>
	);
};

type TestimonialSliderViewProps = {
	testimonials: any[];
};

const TestimonialSliderView = ({ testimonials }: TestimonialSliderViewProps) => {
	const [currentSlide, setCurrentSlide] = useState(0);

	const goToNextSlide = () => {
		setCurrentSlide((prevSlide) => (prevSlide + 1) % Math.floor(testimonials.length / 2));
	};

	const goToPreviousSlide = () => {
		setCurrentSlide((prevSlide) => (prevSlide - 1 + Math.floor(testimonials.length / 2)) % Math.floor(testimonials.length / 2));
	};

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Paper sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '50%',
				backgroundColor: '#FFFFFF',
				boxShadow: 3,
				padding: 1,
				marginRight: 4,
				cursor: 'pointer',
				'&:hover': { backgroundColor: '#F0F0F0' }
			}} onClick={goToPreviousSlide}>
				<ArrowBack sx={{ color: '#20C997', fontSize: '28px', strokeWidth: 1.5, }} />
			</Paper>

			<Grid container spacing={2} justifyContent="center" sx={{ overflowX: 'auto', paddingBottom: 2 }}>
				{testimonials.slice(currentSlide * 2, currentSlide * 2 + 2).map((testimonial, index) => (
					<Grid item xs={12} md={6} key={index}>
						<Paper sx={{ padding: 4, backgroundColor: '#FFFFFF', minHeight: '250px', display: 'flex', flexDirection: 'column' }}>
							<Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
								<Avatar
									src={testimonial.avatarUrl}
									sx={{ width: 60, height: 60, marginRight: 2 }}
								/>
								<Box>
									<Typography variant="h6">{testimonial.name}</Typography>
									<Typography sx={{ color: '#555' }}>{testimonial.title}</Typography>
								</Box>
							</Box>
							<Box sx={{ flex: 1 }}>
								<Typography sx={{ fontStyle: 'italic', marginBottom: 2 }}>
									"{testimonial.testimonial}"
								</Typography>
							</Box>
							<Box sx={{ alignSelf: 'flex-end' }}>
								<Rating value={testimonial.rating} readOnly />
							</Box>
						</Paper>
					</Grid>
				))}
			</Grid>


			<Paper sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '50%',
				backgroundColor: '#FFFFFF',
				boxShadow: 3,
				padding: 1,
				marginLeft: 4,
				cursor: 'pointer',
				'&:hover': { backgroundColor: '#F0F0F0' }
			}} onClick={goToNextSlide}>
				<ArrowForward sx={{ color: '#20C997', fontSize: '28px', strokeWidth: 1.5, }} />
			</Paper>
		</Box>
	);
};

export default TestimonialsView;
